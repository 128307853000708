<template>
	<v-card class="d-flex flex-column my-card">
		<v-row no-gutters dense class="d-flex align-start flex-grow-0 pb-6">
			<v-col cols="12" sm="6">
				<p class="heading-sf20">Bank Account</p>
			</v-col>
			<v-col cols="6" v-if="$store.state.setting.distributor.role != 'AU' && isShowConnectButton" class="d-sm-flex justify-end d-none">
				<v-btn class="my-auto-btn my_elevation" id="my_elevation" @click="connectBank()" depressed>Connect Bank Account</v-btn>
			</v-col>
			<v-col cols="6" v-else-if="$store.state.setting.distributor.role != 'AU'" class="d-sm-flex justify-end d-none">
				<v-btn class="my-auto-btn my_elevation" id="my_elevation" @click="updateAccount()" depressed>Update Bank Information</v-btn>
			</v-col>
		</v-row>

		<v-row no-gutters dense>
			<v-col cols="12">
				<v-row no-gutters dense>
					<v-col cols="12" class="pb-3">
						<p class="heading-sf15">Business Information</p>
					</v-col>
				</v-row>
				<v-row no-gutters dense>
					<v-col cols="4">
						<p class="heading-rf13 pb-2">Business Name :</p>
					</v-col>
					<v-col cols="8">
						<p class="heading-rf15">{{ this.bank_information.business_name }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters dense>
					<v-col cols="4">
						<p class="heading-rf13 pb-2">Contact Number :</p>
					</v-col>
					<v-col cols="8">
						<p class="heading-rf15">{{ this.bank_information.contact_number }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters dense>
					<v-col cols="4">
						<p class="heading-rf13 pb-2">Email :</p>
					</v-col>
					<v-col cols="8">
						<p class="heading-rf15">{{ this.bank_information.email }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters dense>
					<v-col cols="12" class="pb-3">
						<p class="heading-sf15">Bank Information</p>
					</v-col>
				</v-row>
				<v-row no-gutters dense>
					<v-col cols="4">
						<p class="heading-rf13 pb-2">Bank Name :</p>
					</v-col>
					<v-col cols="8">
						<p class="heading-rf15">{{ this.bank_information.bank_name }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters dense>
					<v-col cols="4">
						<p class="heading-rf13 pb-2">Account No. :</p>
					</v-col>
					<v-col cols="8">
						<p class="heading-rf15">{{ this.bank_information.account_no }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters dense>
					<v-col cols="4">
						<p class="heading-rf13 pb-2">Routing No. :</p>
					</v-col>
					<v-col cols="8">
						<p class="heading-rf15">{{ this.bank_information.routing_no }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters dense>
					<v-col cols="4">
						<p class="heading-rf13 pb-2">Status :</p>
					</v-col>
					<v-col cols="8">
						<p class="heading-rf15">{{ this.bank_information.status }}</p>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row v-if="!isShowConnectButton && $store.state.setting.distributor.role != 'AU'" no-gutters dense align="stretch">
			<v-col cols="12" class="d-flex align-end flex-column mt-auto pt-4">
				<a href="javascript:void(0)" class="link-btn font-weight-semibold" @click="viewAccountTransactions()"> View Transactions </a>
			</v-col>
		</v-row>
		<v-col cols="12" v-if="$store.state.setting.distributor.role != 'AU' && isShowConnectButton" class="d-sm-none d-flex pa-0">
			<v-btn class="my-auto-btn my_elevation" id="my_elevation" @click="connectBank()" depressed style="width: 100%">Connect Bank Account</v-btn>
		</v-col>
		<v-col cols="12" v-else-if="$store.state.setting.distributor.role != 'AU'" class="d-sm-none d-flex pa-0">
			<v-btn class="my-auto-btn my_elevation" id="my_elevation" @click="updateAccount()" depressed style="width: 100%">Update Bank Information</v-btn>
		</v-col>
	</v-card>
</template>

<script>
import { client_id, Base_URL } from '../../config/variable'
import { get } from '../../services/httpservices'
export default {
	data() {
		return {
			active_tab: false,
			bank_information: {},
		}
	},

	created() {
		this.$store.watch(
			(state) => {
				return state.setting.company, state.setting.setting_tab
			},
			() => {
				if (this.$store.state.setting.setting_tab.tab === 'account_transaction') {
					this.bank_information = this.$store.state.setting.bank_information
				}
			},
			{ deep: true }
		)
	},

	computed: {
		isShowConnectButton() {
			return this.bank_information.hasOwnProperty('bank_name') && (!this.bank_information?.bank_name || this.bank_information?.bank_name === '-')
		},
	},

	async mounted() {
		this.bank_information = this.$store.state.setting.bank_information
	},

	methods: {
		connectBank() {
			window.location =
				'https://connect.stripe.com/express/oauth/authorize?redirect_uri=' +
				`${Base_URL}/stripe/callback` +
				'&client_id=' +
				client_id +
				'&state=' +
				this.$store.state.auth.user_data.email +
				'&stripe_user[email]=' +
				this.$store.state.auth.user_data.email +
				'&suggested_capabilities[]=transfers&suggested_capabilities[]=card_payments'
		},

		async updateAccount() {
			try {
				const { data } = await get('account-update-link')
				window.location = data.data
			} catch (error) {}
		},

		async viewAccountTransactions() {
			try {
				const { data } = await get('view-account-transactions')
				window.open(data.data)
			} catch (error) {}
		},
	},
}
</script>

<style lang="scss" scoped>
.link-btn {
	background: #fff !important;
	color: #2974ff !important;
}
.link-btn:hover {
	text-decoration: underline;
}
</style>
